import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faX} from "@fortawesome/free-solid-svg-icons";
import {goals, voices, socialMediaOptions, numericOptions} from './Constants';
import {cloneDeep} from "lodash";

const PostSettingsGenerator = ({postSettings, onPostSettingsUpdate, onCopy, onDelete, companyCategories, id}) => {
    let currentPostSettings = postSettings.find(settings => settings.id === id);
    if(!currentPostSettings) {
        return null;
    }

    const updateCurrentPostSettings = (newSettingsGroup) => {
        let newSettings = postSettings.map(settingsGroup => {
            if(settingsGroup.id === id) {
                return newSettingsGroup;
            } else {
                return cloneDeep(settingsGroup);
            }
        });
        onPostSettingsUpdate(newSettings);
    }

    const getClonedCurrentSettingsGroup = () => {
        let groupToClone = postSettings.find(settingsGroup => settingsGroup.id === id);
        if(groupToClone) {
            return groupToClone;
        } else {
            return null;
        }
    }

    const handleCategoryChange = (category, isChecked) => {
        let newTotalSettings = cloneDeep(postSettings);
        let newSettingsGroup = newTotalSettings.find(settingsGroup => settingsGroup.id === id);
        if(newSettingsGroup) {
            let newSpecialties = newSettingsGroup.selectedSpecialties;
            if (isChecked) {
                newSpecialties.push(category);
            } else {
                newSpecialties = newSpecialties.filter(speciality => category !== speciality);
            }
            newSettingsGroup.selectedSpecialties = newSpecialties;
            onPostSettingsUpdate(newTotalSettings);
        }
    };

    let categories = [];
    if(companyCategories?.length > 0) {
        categories = companyCategories.map(category => category);
    }

    const onChangePlatform = (e) => {
        let clonedCurrentSettings = getClonedCurrentSettingsGroup();
        if(clonedCurrentSettings) {
            clonedCurrentSettings.platform = e.target.value;
            updateCurrentPostSettings(clonedCurrentSettings);
        }
    }

    const onChangeCount = (e) => {
        let clonedCurrentSettings = getClonedCurrentSettingsGroup();
        if(clonedCurrentSettings) {
            clonedCurrentSettings.postCount = e.target.value;
            updateCurrentPostSettings(clonedCurrentSettings);
        }
    }

    const onChangeVoice = (e) => {
        let clonedCurrentSettings = getClonedCurrentSettingsGroup();
        if(clonedCurrentSettings) {
            clonedCurrentSettings.voice = e.target.value;
            updateCurrentPostSettings(clonedCurrentSettings);
        }
    }

    const onChangeGoal = (e) => {
        let newGoal = goals.find(goal => goal.description === e.target.value);
        let clonedCurrentSettings = getClonedCurrentSettingsGroup();
        if(clonedCurrentSettings) {
            clonedCurrentSettings.goal = {...newGoal};
            updateCurrentPostSettings(clonedCurrentSettings);
        }
    }

    const onChangeRequest = (e) => {
        let clonedCurrentSettings = getClonedCurrentSettingsGroup();
        if(clonedCurrentSettings) {
            clonedCurrentSettings.userRequest = e.target.value;
            updateCurrentPostSettings(clonedCurrentSettings);
        }
    }

    const isCategoryPostGoal = (postGoal) => {
        return true;
        //TODO
    }

    return (
        <div style={{backgroundColor: 'lightgray', padding: 25, borderColor: 'black', borderWidth: '2px', borderStyle: 'solid', borderRadius: 20}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <FontAwesomeIcon fixedWidth onClick={() => {onCopy()}} size={'lg'} icon={faCopy} />
                <FontAwesomeIcon fixedWidth onClick={() => {onDelete()}} size={'lg'} icon={faX} color={'red'} />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                <h4 style={{marginRight: 10, whiteSpace: "nowrap"}}>Platform</h4>
                <select value={currentPostSettings.platform} onChange={onChangePlatform}>
                    {
                        socialMediaOptions.map(platformOption => (
                                <option key={platformOption} value={platformOption}>
                                    {platformOption}
                                </option>
                            )
                        )
                    }
                </select>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                <h4 style={{marginRight: 10, whiteSpace: "nowrap"}}>Post Count</h4>
                <select style={{minWidth: 50, textAlign: 'center'}} value={currentPostSettings.postCount} onChange={onChangeCount}>
                    {numericOptions.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                <h4 style={{marginRight: 10, whiteSpace: "nowrap"}}>Voice</h4>
                <select value={currentPostSettings.voice} onChange={onChangeVoice}>
                    {
                        voices.map(voiceOption => (
                                <option key={voiceOption} value={voiceOption}>
                                    {voiceOption}
                                </option>
                            )
                        )
                    }
                </select>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                <h4 style={{marginRight: 10, whiteSpace: "nowrap"}}>Goal</h4>
                <select style={{width: 225, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}} value={currentPostSettings.goal.description} onChange={onChangeGoal}>
                    {
                        goals.map(goalOption => (
                                <option key={goalOption.displayName} value={goalOption.description}>
                                    {goalOption.displayName}
                                </option>
                            )
                        )
                    }
                </select>
            </div>

            {isCategoryPostGoal(currentPostSettings.goal) && (
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                    <h4 style={{ whiteSpace: "nowrap" }}>Focus Categories</h4>
                    {categories.map(currentCategory => (
                        <label key={currentCategory} style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                value={currentCategory}
                                checked={currentPostSettings.selectedSpecialties.find((category) => {
                                    return category === currentCategory;
                                }) !== undefined}
                                onChange={
                                    (e) => handleCategoryChange(currentCategory, e.target.checked)
                                }
                            />
                            <span style={{ marginLeft: 5 }}>{currentCategory}</span>
                        </label>
                    ))}
                </div>
            )}
            <div className="special-requests">
                <h4 className="request-label">
                    Special Requests
                </h4>
                <textarea style={{width: 275}} value={currentPostSettings.userRequest} onChange={onChangeRequest} rows={4} cols={50} />
            </div>
        </div>
    )
}

export default PostSettingsGenerator;
