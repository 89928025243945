import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({ text }) => {
    return (
        <div className="loading">
            <div className="loading-spinner"></div>
            <p className="loading-text">{text}</p>
        </div>
    );
};

Loading.propTypes = {
    text: PropTypes.string,
};

export default Loading;
