import React from 'react';
import './Modal.css';
import Loading from "./Loading";

const COLORS = {
    'FACEBOOK': '#3b5998',
    'TWITTER': '#00acee',
    'INSTAGRAM': '#d62976',
    'GOOGLE': '#DB4437',
    'GOOGLE BUSINESS PROFILE': '#DB4437',
}

const Modal = ({ isOpen, onClose, responses }) => {


    const renderStatus = (response) => {
        if(response.loading) {
            return <Loading text={`${response.category} (loading)`} />
        } else if(response?.data?.error) {
            return <p>{`${response.category} (error)`}</p>
        } else {
            return <p>{`${response.category} (loaded)`}</p>
        }
    }

    if (!isOpen) return null;
    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Generated Posts:</h2>
                <div className="container">
                    {responses.map((response) => {
                        if (response?.data?.success) {
                            return (<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                                <h2>{renderStatus(response)}</h2>
                                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', rowGap: 5, columnGap: 25, justifyContent: 'center'}}>
                                    {
                                        response.data?.data?.map((post, postIndex) => {
                                            return (
                                                <div key={postIndex} className="item" style={{display: 'flex', flexDirection: 'column', width: 300, padding: 6, marginBottom: 10, borderRadius: 5, backgroundColor: COLORS[post.platform.toUpperCase()]}}>
                                                    <div style={{backgroundColor: '#eee', display: 'flex', flex: 1, flexDirection: 'column'}}>
                                                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: COLORS[post.platform.toUpperCase()]}}>
                                                            <p>{post.platform}</p>
                                                        </div>
                                                        <p style={{padding: 5}}>{post.post}</p>
                                                    </div>

                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>);
                        } else {
                            return  <h2>{renderStatus(response)}</h2>;
                        }
                    })}
                </div>
                <button style={{marginTop: 20}} onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default Modal;
