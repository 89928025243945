// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(32,18,18,0.5);
    overflow-y: auto;
    z-index: 9999;
}

.modal-content {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 600px; /* Adjust the height as needed */
    overflow: auto;
    padding: 30px;
}

.item {
    transition: transform 0.3s ease;
}

.item:hover {
    transform: scale(1.1);
}

.item p {
    margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oCAAoC;IACpC,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;IAC7B,aAAa,EAAE,gCAAgC;IAC/C,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".modal {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(32,18,18,0.5);\n    overflow-y: auto;\n    z-index: 9999;\n}\n\n.modal-content {\n    background-color: #f2f2f2;\n    padding: 20px;\n    border-radius: 5px;\n    width: 90%;\n}\n\n.container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-around;\n    height: 600px; /* Adjust the height as needed */\n    overflow: auto;\n    padding: 30px;\n}\n\n.item {\n    transition: transform 0.3s ease;\n}\n\n.item:hover {\n    transform: scale(1.1);\n}\n\n.item p {\n    margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
