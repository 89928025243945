export const MODELS = ['gpt-3.5-turbo', 'text-davinci-003'];
export const socialMediaOptions = [
    "Facebook",
    "Twitter",
    "Instagram",
    "GBP"
];
export const numericOptions = Array.from({ length: 16 }, (_, i) => i + 1);
export const voices = [
    "casual/relaxed",
    "formal/serious",
    "playful/fun",
    "humorous/witty",
    "positive/inspiring",
    "aggressive/curt",
    "gentle/delicate",
    "warm/caring"
];
export const SERVICE_HIGHLIGHT = {
    displayName: "Product/Service Highlight",
    description: "to generate leads and promote brand awareness",
};
export const BUSINESS_HIGHLIGHT = {
    displayName: "Business Highlight",
    description: "to highlight why customers should choose this company over its competitors",
};
export const CUSTOMER_COMMUNITY_APPRECIATION = {
    displayName: "Customer/Community Appreciation",
    description: "to showcase the company's support for its customers and community",
};
export const TESTIMONIALS = {
    displayName: "Testimonials",
    description: "to showcase examples of customer satisfaction",
};
export const EDUCATIONAL_CONTENT = {
    displayName: "Educational Content",
    description: "to share not just what the company does, but the why and how behind it",
}
export const DIGITAL_CROSS_PROMOTION = {
    displayName: "Digital Cross-Promotion",
    //description: "to spread awareness of the company's other social media accounts",
    description: "to highlight another one of the company's social media accounts and should include URLs or handles when possible",
    //description: "to highlight another one of the company's social media accounts",
}
export const goals = [SERVICE_HIGHLIGHT, BUSINESS_HIGHLIGHT, CUSTOMER_COMMUNITY_APPRECIATION, TESTIMONIALS, EDUCATIONAL_CONTENT, DIGITAL_CROSS_PROMOTION];
export const COLORS = {
    'FACEBOOK': '#3b5998',
    'TWITTER': '#00acee',
    'INSTAGRAM': '#d62976',
    'GOOGLE': '#DB4437',
    'GOOGLE BUSINESS PROFILE': '#DB4437',
};
