import React, { useState } from "react";

function AutocompleteSearchBar({ options, onSelect }) {
    const [inputValue, setInputValue] = useState("");
    const [showResults, setShowResults] = useState(false);
    const [matchedOptions, setMatchedOptions] = useState([]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value.toLowerCase();
        setInputValue(inputValue);

        if (inputValue === "") {
            setShowResults(false);
            setMatchedOptions([]);
            return;
        }

        const matchedOptions = options.filter((option) =>
            option.name.toLowerCase().includes(inputValue)
        );
        setMatchedOptions(matchedOptions.slice(0, 5));
        setShowResults(true);
    };

    const handleOptionSelect = (option) => {
        onSelect(option);
        setInputValue("");
        setShowResults(false);
        setMatchedOptions([]);
    };

    const handleShowMoreClick = () => {
        setMatchedOptions(options.filter((option) =>
            option.name.toLowerCase().includes(inputValue)
        ));
    };

    return (
        <div className="autocomplete-searchbar" style={{display: "flex", alignItems: "center", flexDirection: 'column'}}>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Search..."
            />
            {showResults && (
                <div className="autocomplete-results">
                    {matchedOptions.map((option) => (
                        <div
                            key={option.client_id}
                            className="autocomplete-result"
                            onClick={() => handleOptionSelect(option)}
                        >
                            {option.name}
                        </div>
                    ))}
                    {matchedOptions.length === 0 && <div>No results found.</div>}
                    {matchedOptions.length > 5 && (
                        <button onClick={handleShowMoreClick}>Show more</button>
                    )}
                </div>
            )}
        </div>
    );
}

export default AutocompleteSearchBar;
