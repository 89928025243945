import React, {useState, useEffect, useRef} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import AutoCompleteSearchBar from './AutoCompleteSearchBar';
import Modal from "./Modal";
import PostSettingsGenerator from "./PostSettingsGenerator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from 'uuid';
import {
    goals,
    voices,
    numericOptions,
    socialMediaOptions,
    MODELS,
    SERVICE_HIGHLIGHT,
    BUSINESS_HIGHLIGHT, DIGITAL_CROSS_PROMOTION, CUSTOMER_COMMUNITY_APPRECIATION, EDUCATIONAL_CONTENT
} from './Constants'
import {cloneDeep} from "lodash";

//TODO list
//refactor buildPrompt and break it up by goal type for easier specific goal tweaking
//look into cases where OpenAI returns [facebook.url] or [instagram handle] instead of the actual link (seems to be GBP only)
//handle speciality cases where client speciality should be capitalized (like "DUI law" for instance) but isn't always capitalized
//fix case where some GBP posts are coming back not as strings but as objects and crashing the site

function App() {
    const API_KEY = process.env.REACT_APP_API_KEY;
    const generatorDefaultSettings = {
        goal: goals[0],
        voice: voices[0],
        platform: socialMediaOptions[0],
        postCount: numericOptions[0],
        userRequest: '',
        selectedSpecialties: [],
    };

    const defaultPostSettings = [
        {
            id: uuidv4(),
            goal: SERVICE_HIGHLIGHT,
            voice: voices[0],
            platform: socialMediaOptions[0],
            postCount: 6,
            userRequest: '',
            selectedSpecialties: [],
        },
        {
            id: uuidv4(),
            goal: BUSINESS_HIGHLIGHT,
            voice: voices[0],
            platform: socialMediaOptions[0],
            postCount: 4,
            userRequest: '',
            selectedSpecialties: [],
        },
        {
            id: uuidv4(),
            goal: DIGITAL_CROSS_PROMOTION,
            voice: voices[0],
            platform: socialMediaOptions[0],
            postCount: 2,
            userRequest: '',
            selectedSpecialties: [],
        },
        {
            id: uuidv4(),
            goal: CUSTOMER_COMMUNITY_APPRECIATION,
            voice: voices[0],
            platform: socialMediaOptions[0],
            postCount: 2,
            userRequest: '',
            selectedSpecialties: [],
        },
        {
            id: uuidv4(),
            goal: EDUCATIONAL_CONTENT,
            voice: voices[0],
            platform: socialMediaOptions[0],
            postCount: 2,
            userRequest: '',
            selectedSpecialties: [],
        },
    ];

    const [model, setModel] = useState(MODELS[0])
    const [startDate, setStartDate] = useState(new Date());
    const [prompts, setPrompts] = useState([]);
    const [chatGPTOutput, setChatGPTOutput] = useState([]);
    const [socialMedia, setSocialMedia] = useState([
        { platform: socialMediaOptions[0], value: numericOptions[0] },
    ]);

    const [voice, setVoice] = useState(voices[0]);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(undefined);
    const [selectedClientData, setSelectedClientData] = useState(undefined);
    const [displayPosts, setDisplayPosts] = useState(false);
    const [postSettings, setPostSettings] = useState(defaultPostSettings);
    const [isUserCustomizationEnabled, setIsUserCustomizationEnabled] = useState(false);
    const [clientGBPInformation, setClientGBPInformation] = useState(null);
    const abortController = useRef();

    const endpointURL = 'https://dev-jon.webservices.sfs.io/api';

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(`${endpointURL}/clients`, {
                headers: {
                    'Authorization': `Bearer ${API_KEY}`
                }
            });
            const data = await response.json();
            setClients(data);
        }
        if(API_KEY) {
            fetchData().then();
        }
    }, [API_KEY]);

    const removeNullAndEmptyArrays = (obj) => {
        if (typeof obj === 'object' && obj !== null) {
            if (Array.isArray(obj)) {
                obj = obj.filter(Boolean); // Remove null and empty values from array
            } else {
                for (let key in obj) {
                    obj[key] = removeNullAndEmptyArrays(obj[key]); // Recursively remove null and empty values from object properties
                    if (obj[key] === null || obj[key] === undefined || (Array.isArray(obj[key]) && obj[key].length === 0)) {
                        delete obj[key]; // Remove null or empty array values from object
                    }
                }
            }
        }
        return obj;
    }

    const getActiveSocialMedias = () => {
        let retArray = [];
        if (selectedClientData.hasOwnProperty("instagram_handle") && selectedClientData.instagram_handle) {
            retArray.push("Instagram");
        }

        if (selectedClientData.hasOwnProperty("facebook_page_url") && selectedClientData.facebook_page_url) {
            retArray.push("Facebook");
        }

        if (selectedClientData.hasOwnProperty("twitter_handle") && selectedClientData.twitter_handle) {
            retArray.push("Twitter");
        }
        return retArray;
    }

    function formatPhoneNumber(phoneNumber) {
        // Remove any non-digit characters from the input
        const digitsOnly = phoneNumber.replace(/\D/g, '');
        if (digitsOnly.length === 10) {
            // Format as (XXX) XXX-XXXX
            return digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        } else if (digitsOnly.length === 11) {
            // Format as +X (XXX) XXX-XXXX
            return digitsOnly.replace(/(\d)(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
        } else {
            return phoneNumber;
        }
    }

    const handleCheckboxChange = (event) => {
        setIsUserCustomizationEnabled(event.target.checked);
        if(!event.target.checked) {
            let selectedSpecialties = createCompanyCategoriesList();
            defaultPostSettings.forEach((settingsGroup) => {
                settingsGroup.selectedSpecialties = [...selectedSpecialties];
            })
            setPostSettings(defaultPostSettings);
        }
    };

    const buildPrompt = (settings) => {
        const monthName = startDate.toLocaleString('default', { month: 'long' });
        let companyInfo = {
            phone: selectedClientData.phone,
            email: selectedClientData.email,
            location: selectedClientData.location,
            website: selectedClient.url,
            specialties: selectedClientData.yextCategories?.length > 0 && settings.selectedSpecialties.length > 0 ? settings.selectedSpecialties : [],
        };
        let companySocials = {};

        if(selectedClientData.facebook_page_url) {
            companySocials.facebook = {handle: selectedClientData.facebook_page_url};
        }

        if(selectedClientData.twitter_handle) {
            companySocials.twitter = {handle: selectedClientData.twitter_handle};

        }
        if(selectedClientData.instagram_handle) {
            companySocials.instagram = {handle: selectedClientData.instagram_handle};
        }

        companyInfo = removeNullAndEmptyArrays(companyInfo);
        companyInfo.phone = formatPhoneNumber(companyInfo.phone);

        let socialMediasArray = getActiveSocialMedias();

        let platform = '';
        if(settings.platform === 'GBP') {
            platform = 'Google Business Profile';
        } else if(socialMediasArray.length === 1) {
            platform = socialMediasArray[0];
        } else if(socialMediasArray.length > 1) {
            let lastItem = socialMediasArray.pop();
            platform = `a mix of ${socialMediasArray.join(',')} and ${lastItem}`;
        }

        const prompt = `Create ${platform} posts for a company called ${selectedClient.name}.`;
        let request = { platform, prompt, loading: true, postCount: settings.value };

        if (selectedClientData.clientIndustries?.length > 0 && selectedClientData.yextCategories.length === 0) {
            const industryPrompt =
                settings.selectedSpecialties.length === 1
                    ? `The company is part of the ${settings.selectedSpecialties[0]} industry.`
                    : settings.selectedSpecialties.length > 1 ? `The company is part of the ${settings.selectedSpecialties.join(', ')} industries.`
                    : '';
            request.prompt += ` ${industryPrompt}`;
        }
        request.prompt += ` The ${socialMedia.length > 1 ? 'posts' : 'post'} will be published in ${monthName}.`;
        if (voice?.length > 0) {
            request.prompt += ` Use a ${voice} voice for the posts.`;
        }

        if(settings.goal.description === DIGITAL_CROSS_PROMOTION.description) {
            //if this is a GBP request, don't send the GBP profile
            if(clientGBPInformation && platform !== 'Google Business Profile') {
                companySocials.googleBusinessProfile = {exists: true};
            }

            if(Object.keys(companySocials).length > 1 || (Object.keys(companySocials).length === 1 && platform === 'Google Business Profile')) {
                request.prompt += ` The goal of each post is ${settings.goal.description}.`;
                request.prompt += ` Here are the company's social media accounts to promote: ${JSON.stringify(companySocials)}`
            } else if(Object.keys(companySocials).length === 1 && selectedClient.url && platform !== 'Google Business Profile') {
                companySocials.website = {exists: selectedClient.url ? true : false, link: selectedClient.url};
                request.prompt += ` The goal of each post is to drive traffic to the company website. Here is information about the company's online presence: ${JSON.stringify(companySocials)}`;
            } else if(Object.keys(companySocials).length === 1 && selectedClient.url) {

            } else {
                //TODO we fall back to some other post category for these since adequate social media accounts don't exist
                request.prompt += ` The goal of each post is ${SERVICE_HIGHLIGHT.description}.`;
            }
        } else {
            request.prompt += ` The goal of each post is ${settings.goal.description}.`;
        }

        if(platform === 'Google Business Profile') {
            request.prompt += ` Do not use hashtags in any of the posts.`
        }

        request.category = settings.goal.displayName;

        if (settings.userRequest.length > 0) {
            request.prompt += ` ${settings.userRequest}.`;
        }

        if(Object.keys(companyInfo).length > 0) {
            let temp;
            if (request.platform === "Google Business Profile") {
                temp = companyInfo.phone;
                delete companyInfo.phone;
            }
            request.prompt = `${request.prompt}\n\nHere is more information about the company:\n${JSON.stringify(companyInfo)}\n`;
            companyInfo.phone = companyInfo.phone || temp;
        }
        request.model = model;

        if(settings.goal.description === DIGITAL_CROSS_PROMOTION.description && (Object.keys(companySocials).length > 1 || Object.keys(companySocials).length === 1 && selectedClient.url)) {
            request.prompt += `\n\nThe returned data format should be an array containing ${settings.postCount} JSON ${settings.postCount === 1 ? 'object' : 'objects'} where each object has the keys "platform," "promotedPlatform," and "post." The platform the post will be published on and the platform the post highlights should not be the same.`;
        } else {
            request.prompt += `\n\nThe returned data format should be an array containing ${settings.postCount} JSON ${settings.postCount === 1 ? 'object' : 'objects'} where each object has the keys "platform" and "post."`;
        }

        return request;
    }


    const handleSendRequests = async () => {
       if(abortController.current) {
           //cancel any in-progress requests because we're about to generate more
           abortController.current.abort();
       }

       abortController.current = new AbortController();
       const {signal} = abortController.current;

       const requests = postSettings.map(settingGroup => {
           return buildPrompt(settingGroup);
       });
       if(clientGBPInformation) {
           let gbpRequests = postSettings.map(settingGroup => {
               let newSettings = {...settingGroup, platform: 'GBP'};
               return buildPrompt(newSettings);
           });
           requests.push(...gbpRequests);
       }

        setPrompts(requests);

       const fetchPromises = requests.map((promptItem) => {
            setChatGPTOutput((prevData) => {
                const currentOutput = { category: promptItem.category, platform: promptItem.platform, loading: true, data: null };
                const updatedData = [...prevData];
                const index = updatedData.findIndex((item) => item.category === currentOutput.category);
                if (index !== -1) {
                    updatedData[index] = {...currentOutput};
                } else {
                    updatedData.push({...currentOutput});
                }
                return updatedData;
            });

            return fetch(`${endpointURL}/talk`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${API_KEY}`
                },
                signal: signal,
                body: JSON.stringify(promptItem),
            })
                .then((response) => response.json())
                .then((data) => {
                    setChatGPTOutput((prevData) => {
                        let oldStateCopy = prevData.map(platform => {return {...platform}});
                        if(data?.data?.length > 0) {
                            let categoryIndex = prevData.findIndex(item => item.category === promptItem.category);
                            if(categoryIndex >= 0) {
                                let clonedPlatformItem = {...oldStateCopy[categoryIndex], loading: false};
                                clonedPlatformItem.data = {...clonedPlatformItem.data, success: true};
                                let newPosts = data.data;
                                if(promptItem.platform === 'Google Business Profile') {
                                    newPosts = newPosts.map(post => {
                                        return {...post, platform: 'Google Business Profile'}
                                    });
                                }
                                if(clonedPlatformItem.data.data) {
                                    clonedPlatformItem.data.data = clonedPlatformItem.data.data?.map(post => {return {...post}});
                                    clonedPlatformItem.data.data = [...clonedPlatformItem.data.data, ...newPosts];
                                } else {
                                    clonedPlatformItem.data.data = [];
                                    clonedPlatformItem.data.data = [...clonedPlatformItem.data.data, ...newPosts];
                                }
                                oldStateCopy[categoryIndex] = clonedPlatformItem;
                                return oldStateCopy;
                            } else {
                                throw "category entry did not exist when updating platforms with new posts!";
                            }
                        }
                        return prevData;
                    });
                })
                .catch((error) => {
                    console.error('Error:', error);
                    if(!error.includes('user aborted a request')) {
                        const currentOutput = { category: promptItem.category, platform: promptItem.platform, loading: false, error: true };
                        currentOutput.loading = false;
                        currentOutput.error = true;
                        setChatGPTOutput((prevData) => {
                            const updatedData = [...prevData];
                            const index = updatedData.findIndex((item) => item.platform === currentOutput.platform);
                            if (index !== -1) {
                                updatedData[index] = [...updatedData[index], ...currentOutput];
                            } else {
                                updatedData.push(currentOutput);
                            }
                            return updatedData;
                        });
                    }
                });
        });

        try {
            await Promise.all(fetchPromises);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const fetchClientData = async () => {
            const response = await fetch(`${endpointURL}/clientDetails/${selectedClient.client_id}`, {
                headers: {
                    'Authorization': `Bearer ${API_KEY}`
                },
            });
            const data = await response.json();
            setSelectedClientData(data);
        }

        const fetchClientGMBInformation = async () => {
            const response = await fetch(`${endpointURL}/clientGBPDetails/${selectedClient.client_id}`, {
                headers: {
                    'Authorization': `Bearer ${API_KEY}`
                },
            });
            const data = await response.json();
            if(data?.length > 0 && data.some(gbpLocation => gbpLocation.status === 1)) {
                setClientGBPInformation(data);
            } else {
                setClientGBPInformation(null);
            }
        }

        if (selectedClient) {
            fetchClientData().then();
            fetchClientGMBInformation().then();
        }
    }, [selectedClient]);

    useEffect(() => {
        if(selectedClientData) {
            //set the selected specialties for each settings group to have all initially checked
            let businessSpecialties = createCompanyCategoriesList();
            let newSettings = cloneDeep(postSettings);
            newSettings = newSettings.map(settingsCluster => {
                return {...settingsCluster, selectedSpecialties: [...businessSpecialties]};
            })
            setPostSettings(newSettings);
        }

    }, [selectedClientData]);

    useEffect(() => {
        if(chatGPTOutput?.length > 0 && !displayPosts) {
            setDisplayPosts(true);
        }

        // Check if all requests have finished loading
        const allRequestsLoaded = chatGPTOutput.every((output) => !output.loading);
    }, [chatGPTOutput]);

    const createCompanyCategoriesList = () => {
       let retArray = [];
       if(selectedClientData && (selectedClientData.yextCategories?.length > 0 || selectedClientData.clientIndustries?.length > 0)) {
            if(selectedClientData.yextCategories.length > 0) {
                retArray = selectedClientData.yextCategories.map(category => category.categoryName.toLowerCase());
            } else {
                retArray = selectedClientData.clientIndustries.map(industry => industry.industryName.toLowerCase());
            }
       }
       return retArray;
    }

    const renderBusinessInfo = () => {
        const { yextCategories, clientIndustries } = selectedClientData;
        return (
            <div style={{gap: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <Modal isOpen={displayPosts} onClose={() => {setDisplayPosts(false)}} responses={chatGPTOutput} />
                <div style={{display: 'flex', width: '30%', flexDirection: 'column', alignItems: 'center'}}>
                    <h3>Business Information:</h3>
                    <ul>
                        {Object.keys(selectedClientData).map(item => {
                            if(item === 'yextCategories' || item === 'yextLocationIds' || item === 'clientIndustries') {
                                return null;
                            } else if(selectedClientData[item]) {
                                let retVal = selectedClientData[item];
                                if(retVal?.length > 100) {
                                    //retVal = retVal.slice(0,100) + '...';
                                }
                                return (<li>{`${item}: ${retVal}`}</li>);
                            }
                        })}
                    </ul>
                </div>
                <div style={{display: 'flex', width: '30%', flexDirection: 'column', alignItems: 'center'}}>
                    <h3>Specialty Categories:</h3>
                    <ul>
                        {yextCategories.map((category, index) => (
                            <li key={category.categoryId}>{`${category.categoryName} (${category.categoryId})`}</li>
                        ))}
                    </ul>
                </div>
                <div style={{display: 'flex', width: '30%', flexDirection: 'column', alignItems: 'center'}}>
                    <h3>Specialty Industries:</h3>
                    <ul>
                        {clientIndustries.map((industry, index) => (
                            <li key={industry.industryId}>{`${industry.industryName} (${industry.industryId})`}</li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    return (
        <div className="App" style={{display: 'flex', height: '100vh', width: '100%', flexDirection: 'column', alignItems: 'center', backgroundColor: '#abe2ed', overflowY: 'scroll'}}>
            <div className={"search-input"}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <h4>Client Search:</h4>
                    <AutoCompleteSearchBar options={clients} onSelect={setSelectedClient}/>
                </div>
            </div>

            <div className={"client-info"}>
                <b style={{marginTop: 10, fontSize: 24}}>Selected Client: {selectedClient ? selectedClient.name : 'none'}</b>
               {selectedClientData && renderBusinessInfo()}
            </div>

            <div style={{display: 'flex', flexDirection: 'row', columnGap: 20}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <h4>Month to Publish</h4>
                    <DatePicker dateFormat="MMMM yyyy" showMonthYearPicker selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <h4 style={{marginRight: 10, whiteSpace: "nowrap"}}>Voice</h4>
                    <select value={voice} onChange={(e) => {setVoice(e.target.value);}}>
                        {
                            voices.map(voiceOption => (
                                    <option key={voiceOption} value={voiceOption}>
                                        {voiceOption}
                                    </option>
                                )
                            )
                        }
                    </select>
                </div>
            </div>
            <div style={{marginTop: 25}}>
                <label>
                    <input
                        type="checkbox"
                        checked={isUserCustomizationEnabled}
                        onChange={handleCheckboxChange}
                    />
                    Customize Posts Settings
                </label>
            </div>
            {isUserCustomizationEnabled &&
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                    {postSettings.map(currentPostSettings => {
                        return (
                        <PostSettingsGenerator
                            postSettings={postSettings}
                            onPostSettingsUpdate={setPostSettings}
                            id={currentPostSettings.id}
                            onCopy={() => {
                                let currentGeneratorSettings = postSettings.find((postSettingCollection) => postSettingCollection.id === currentPostSettings.id);
                                let newItem = {};
                                Object.keys(currentGeneratorSettings).forEach(key => {
                                    newItem[key] = currentGeneratorSettings[key];
                                })
                                newItem["id"] = uuidv4();
                                setPostSettings([...postSettings, newItem]);
                            }}
                            onDelete={() => {
                                setPostSettings(postSettings.filter(item => item.id !== currentPostSettings.id));
                            }}
                            companyCategories={createCompanyCategoriesList()}
                            companyInformation={{...selectedClientData, name: selectedClient?.name}}
                        />);
                    })}
                    </div>
                    <div style={{padding: 8, margin: 20, borderRadius: 20, backgroundColor: 'lightgray', borderColor: 'black', borderWidth: '2px', borderStyle: 'solid'}} >
                        <FontAwesomeIcon icon={faPlus} size={"sm"} onClick={() => {
                            setPostSettings(previousSettings => {
                                return [...previousSettings, {id: uuidv4(), ...generatorDefaultSettings, selectedSpecialties: createCompanyCategoriesList()}];
                            })
                        }}/>
                    </div>
                </div>
            }
           <div style={{marginTop: 20}}>
                <button className={`generate-button ${!selectedClient || socialMedia.length === 0 ? 'disabled' : ''}`} disabled={!selectedClient || socialMedia.length === 0} onClick={() => {
                    setChatGPTOutput([]);
                    handleSendRequests().then();
                    }}>Generate Posts</button>
                {chatGPTOutput.length > 0 && <button style={{marginLeft: 20}} onClick={() => {
                    setDisplayPosts(true);
                }}>Show Posts</button>}
            </div>

            <div className={"output"}>
                {prompts?.length > 0 && <div>
                    <p>Generated Prompts</p>
                    <ul>
                        {prompts.map((prompt) => (<li>{prompt.prompt}</li>))}
                    </ul>
                </div>}
            </div>
        </div>
    );
}

export default App;
